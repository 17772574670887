import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatRut',
})
export class FormatRutPipe implements PipeTransform {
  transform(rut: string): string {
    let format: string = rut ? rut.replace(/[^0-9k]/gi, ''): '';

    if (format.length === 0) {
      format = '';
    } else if (format.length <= 4) {
      format = format.replace(/^(\d{0,1})(\d{0,3})/, '$1.$2');
    } else if (format.length <= 5) {
      format = format.replace(/^(\d{0,2})(\d{0,3})/, '$1.$2');
    } else if (format.length <= 6) {
      format = format.replace(/^(\d{0,3})(\d{0,3})/, '$1.$2');
    } else if (format.length <= 7) {
      format = format.replace(/^(\d{0,1})(\d{0,3})(\d{0,3})/, '$1.$2.$3');
    } else if (format.length <= 8) {
      format = format.replace(/^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,1})/, '$1.$2.$3-$4');
    } else {
      format = format.substring(0, 9);
      format = format.replace(/^(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,1})/, '$1.$2.$3-$4');
    }
    return format;
  }
}
